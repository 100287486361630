const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-container container">
        <div className="items">
            <div className="label">Contact</div>
            <div className="item"><a href="https://www.linkedin.com/in/camilavinik/" target="_blank">Linkedin</a></div>
            <div className="item"><a href="https://github.com/camilavinik" target="_blank">Github</a></div>
            <div className="item"><a href="mailto:camilavinik@gmail.com">Email</a></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;